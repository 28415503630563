
// let environment = 'test';
// var host = environment == 'test' ? 'http://weixin.sijiedu.com/appwechat_test/#/' : 'http://weixin.sijiedu.com/appwechat/#/';
// export const HOST = host;
// export const wxAppid = "wx0a7e7d49156369ac"

//格式化日期：yyyy-MM-dd
export function formatDate (date) {
  const myyear = date.getFullYear();
  let mymonth = date.getMonth() + 1;
  let myweekday = date.getDate();
  if (mymonth < 10) {
    mymonth = `0${mymonth}`;
  }
  if (myweekday < 10) {
    myweekday = `0${myweekday}`;
  }
  return (`${myyear}-${mymonth}-${myweekday}`);
}

export const setFull = (data) => {
  return data = data < 10 ? '0' + data : data
}
export const diffTime = (diff) => {
  // var day = parseInt(gap / (24 * 3600))
  // var hour = parseInt(gap % (24 * 3600) / 3600);
  // let min = parseInt(gap % 3600 / 60);
  // var sec = parseInt(gap % 60);
  var Day = Math.floor(diff / (60 * 60 * 24));
  var Hour = Math.floor((diff - Day * 24 * 60 * 60) / 3600);
  var Minute = Math.floor((diff - Day * 24 * 60 * 60 - Hour * 3600) / 60);
  var Second = Math.floor(diff - Day * 24 * 60 * 60 - Hour * 3600 - Minute * 60);
  //var returnStr = Day + '天' + setFull(Hour) + '小时' + setFull(Minute) + '分钟' + setFull(Second) + '秒'
  var returnStr = setFull(Minute) + '分' + setFull(Second) + '秒'
  return returnStr
}
export function checkTel (val) {
  let mobileReg = /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  var mobileFlag = mobileReg.test(val);
  return mobileFlag

}
export const telValidate = [{
  required: true,
  message: '请输入手机号',
  trigger: 'blur'
},
{
  min: 11,
  max: 11,
  message: '请输入手机号',
  trigger: 'blur'
},
{
  pattern: /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/,
  message: '请输入手机号',
  trigger: 'blur'
},
];
export const smgValidate = [{
  required: true,
  message: '请输入短信验证验',
  trigger: 'blur'
},
{
  min: 4,
  max: 4,
  message: '请输入短信验证验',
  trigger: 'blur'
},
{
  pattern: /^[0-9]{4}$/,
  message: '请输入短信验证验',
  trigger: 'blur'
},
];
export const passwordValidate = [{
  required: true,
  message: '请输入密码'
},
{
  min: 8,
  message: '密码长度不够',
},
{
  pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,16}$/,   ///^(?!\d+$)[\da-zA-Z-#$%^&*]*$/   
  message: '8-16位大小写字母、数字、符号~!@#$%^&* 以上组合',
},
];
export const nameValidate = [{
  required: true,
  message: '请填写姓名',
  trigger: 'blur'
},
{
  pattern: /[A-Za-z0-9_\-\u4e00-\u9fa5]+/,
  message: '姓名有非法字符！',
  trigger: 'blur'
},
];
export const schoolValidate = [
{
  pattern: /[A-Za-z0-9_\-\u4e00-\u9fa5]+/,
  message: '有非法字符！',
  trigger: 'blur'
},
];
// 截取url参数
export const GetArgsFromHref = (sArgName) => {
  var sHref = window.location.href;
  var args = sHref.split("#");
  var retval = "";
  if (args[0].indexOf('?') != -1) {
    var argstwo = args[0].split("?");
    if (argstwo[1] == "") {
      return retval;
    } else {
      var str = argstwo[1];
      args = str.split("&");
      for (var i = 0; i < args.length; i++) {
        str = args[i];
        var arg = str.split("=");
        if (arg.length <= 1) continue;
        if (arg[0] == sArgName) retval = arg[1];
      }
      return retval;
    }
  }
}
// 创建图形验证码
export function createCaptcha(id,str) {
  const canvas = document.getElementById(id);
  const context = canvas.getContext('2d');
  const captcha = str;
  // 设置画布样式
  context.fillStyle='#f5f5f5';
  context.fillRect(0, 0, canvas.width, canvas.height);
  context.font='bold 24px Arial';
  context.fillStyle='#000';
  context.textAlign ='center';
  context.textBaseline='middle'

  // 绘制验证码文本
  context.fillText(captcha, canvas.width / 2, canvas.height / 2);

  // 绘制干扰线
  for (let i = 0; i<6; i++) {
    context.strokeStyle = getRandomColor();
    context.beginPath();
    context.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
    context.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
    context.stroke();
  }

  // 返回生成的验证码
  return captcha;
}

// 获取随机颜色
export function getRandomColor() {
  const letters='0123456789ABCDEF';
  let color='#';
  for (let i = 0; i<6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
